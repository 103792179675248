import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const OnlineInstallerPage = () => (
  <div>
      <SEO 
        title="Hepsia’s Website Installer" 
        description="You could now build a new website within 4 straightforward clicks. We have built our own custom–built 
        Website Installer offering you a way to launch your own site on the Internet within a few minutes."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title"> <span className="block text-indigo-600 xl:inline">Hepsia’s Website</span> Installer <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">You could now build a new website within 4 straightforward clicks.</p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a 
                      href="https://demo.hepsia.com/installer/wizard/?auto_login=true" 
                      rel="noreferrer" 
                      target="_blank" 
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      View Demo
                      </a>
                  </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <p className="m-0">
                        <img src="https://sheephostingbay.duoservers.com/template2/img/web-site-installer-banner.webp" alt="Sheephostingbay Web control panel"/>
                    </p>
                  </div>
              </div>
              
            </div>
        </div>
        
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                 <p className="section-after-title">
                 <span role="img" aria-label="emoji">🦄</span> We have built our own custom–built Website Installer offering you a way to launch your 
                 own site on the Internet within a few minutes. You just need to pick the sort of site you desire to have – a weblog, an online 
                 community, a photograph gallery, etc., and after that pick a design and style out of a range of more than two hundred website 
                 templates for personal or company websites. When ready, click on the Install button and your web site is going to be 
                 published on the web and your administrator area login data – sent to your mailbox.
                 <br/><br/>
                 <span role="img" aria-label="emoji">✨ </span> Afterwards, you can start creating unique webpages and then publish pics along with 
                 online videos with a mouse click. If you have problems installing or dealing with your websites, simply let us know through the 
                 ticket system and we’ll be glad to assist in 60 min’s. The Website Installer is readily built into the Sheephostingbay Web 
                 Control Panel with all of the website hosting, VPS packages, semi-dedicated hosting packages, and dedicated servers 
                 hosting packages bundles featured on our store.
                </p>
              
            </div>
        </div>
        
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/a-simple-app-installation.webp" className="w-60" alt="A convenient Website Installation Tool" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A convenient Website Installation Tool</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Making a website is as straightforward as 1–2–3<br/><br/>
                                    Building a web site from the start could be difficult if you don’t possess at the very least a fundamental level 
                                    of HTML and CSS practical knowledge. You have to know PHP and JavaScript also. With our Website Installer, you 
                                    don’t have to have any coding practical knowledge to jumpstart a customizable website. You can pick among 
                                    more than two hundred web themes and promptly alter the picked web template according to your needs 
                                    without needing to generate virtually any code in the backend.
                                    <br/><br/>
                                    In case you don’t enjoy the end result – return and start out again. It takes just simply 4 easy steps, and the 
                                    moment you get to the fourth step – you will have your brand–new web site ready to go.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Over 200 Web Templates Available</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Launch your brand new web site with a click<br/><br/>
                                    To help you have your brand–new web site online immediately, we have developed a selection of website themes to 
                                    choose from. Based on the function of your site, you can find a very good design template for use on your blog 
                                    site or photo gallery or perhaps the ideal style for your personal portfolio. All design themes are available 
                                    for free–of–charge download in your Web Control Panel. We’re also working hard to produce brand–new website 
                                    templates in accordance with the newest style and design tendencies in an effort to supply you with a 
                                    better option for your personal sites.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/free-themes-04.webp" className="w-60" alt="Over 200 Web Templates Available" />
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/support-03.webp" className="w-60" alt="24–hour Technical Support" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">24–hour Technical Support</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Our technical professionals are available night and day<br/><br/>
                                    The technical support staff consists of experts that have been working quite a few years in the hosting industry. 
                                    They are trained to take care of all types of difficulties and are available 24x7, ready to aid you. In addition 
                                    to that, we have Regularly Asked Questions and quite a few how–to video tutorials.
                                    <br/><br/>
                                    Additionally, our company offers a one–hour reply–back time period warranty, so that you will definitely get a 
                                    response to your question in a timely manner. The standard reaction time frame is under 20 minutes.
                                    </div>
                                </div>
                            </div>
                        </div>   

                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default OnlineInstallerPage
